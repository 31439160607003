/* eslint-disable no-new */
import Vue from 'vue'
import axios from 'axios'
import '@services/axiosDefaults'

Vue.config.productionTip = false

new Vue({
  el: '#select-city',

  data: () => ({
    allCities: [],
    initialCity: '',
    initialAlias: '',
    cityId: 1,
    redirectExceptionUrlsList: ['/orderDelivery', '/adresess_terminals'],
  }),

  computed: {
    currentCity() {
      return this.allCities.find(city => city.id === this.cityId) || {}
    },
    cityName() {
      return this.currentCity?.name || this.initialCity
    },
  },

  methods: {
    checkAlias() {
      // Проверка алиаса города в урле
      const isMainNoMoscow = window.location.pathname === '/' && this.cityId !== 1

      if (isMainNoMoscow) {
        const newUrl = `${window.location.origin}/${this.initialAlias}`

        window.history.pushState(null, null, newUrl)
      }
    },
    findUrl(newAlias) {
      if (window.location.pathname === '/') return `${window.location.origin}/${newAlias}`

      const currentLocation =
        window.location.href.at(-1) === '/' ? window.location.href : `${window.location.href}/`
      // Добавляем слэш, чтобы заменять например /spb/ => /ekb/

      let newUrl

      this.allCities.some(city => {
        // Проходим по всем возможным алиасам
        const generatedUrl = currentLocation.replace(`/${city.alias}/`, `/${newAlias}/`)

        if (generatedUrl !== currentLocation) {
          newUrl = generatedUrl
          return true
        }

        return false
      })

      const resultUrl = newUrl || currentLocation

      return resultUrl.slice(0, -1) // Убираем добавленный слеш
    },
    updateUrl(isRedirect = true) {
      const pathName = window.location.pathname
      const isExceptionUrl = this.redirectExceptionUrlsList.includes(pathName)
      const newUrl = this.findUrl(this.currentCity.alias)

      if (isRedirect && !isExceptionUrl) {
        window.location.href = newUrl
        return
      }

      window.history.pushState(null, null, newUrl)
    },
    async setSessionCity(id, isRedirect = true) {
      this.cityId = +id
      await axios.get(`/setSessionCity/?id=${id}`) // Отправление запроса на установку города для сессии

      this.updateUrl(isRedirect)
    },
    setInitialData() {
      // Window расширяется с помощью php данными города сесси пользователя
      this.cityId = window.cityId
      this.initialCity = window.cityParams.name
      this.initialAlias = window.cityParams.alias
      this.allCities = window.citiesData
    },
  },

  created() {
    this.setInitialData() // Устанавливаем изначальные данные
    this.checkAlias() // Проверяем алиаса города в урле
    window.setSessionCity = this.setSessionCity.bind(this) // Возможность установки города вне этого Vue экземпляра
  },
})
